import '../wdyr';
import '@expo/match-media';
import '#i18n/utils/fbtInit';
import '#lib/defineUndefined';

import { defineCustomElements } from '@duetds/date-picker/dist/loader';
import * as Sentry from '@sentry/browser';
import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';
import { NextPage } from 'next';
import { Session } from 'next-auth';
import { Provider } from 'next-auth/client';
import React from 'react';
import { Text } from 'react-native';

import AntDesign from '../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/AntDesign.ttf';
import FontAwesome from '../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf';
import MaterialCommunityIcons from '../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import Octicons from '../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Octicons.ttf';
import OfficinaSans from '../public/fonts/OfficinaSansStd-Bold.otf';
import Roboto from '../public/fonts/Roboto-Regular.ttf';

Sentry.init({
  dsn: process.env.SENTRY_DSN_WEB,
  release: process.env.VERCEL_GITLAB_COMMIT_REF, // The GitLab branch that the deployment was triggered. Example: `release/2.1.0`
});

// Register Duet Date Picker
if (typeof window !== 'undefined') defineCustomElements(window);

const App = ({
  Component,
  pageProps,
  err,
}: {
  Component: NextPage<{ err?: Error }>;
  pageProps: Record<string, unknown> & { session?: Session | null };
  err: Error;
}): React.ReactElement => {
  const [fontsLoaded] = useFonts({
    Roboto,
    OfficinaSans,
    AntDesign,
    FontAwesome,
    MaterialCommunityIcons,
    Octicons,
  });

  if (!fontsLoaded) return <Text>Loading font...</Text>;

  const modifiedPageProps = { ...pageProps, err };
  return (
    <Provider session={modifiedPageProps.session ?? undefined}>
      <Component {...modifiedPageProps} />
    </Provider>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default App;
