/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    exclude: [
      /Pressable/,
      /TouchableWithoutFeedback/,
      /TouchableOpacity/,
      /PortalManager/,
      /Button/,
      /RespondableItem/,
      /ViewWithoutOverflow/,
      /Icon/,
    ],
  });
}
