/* eslint-disable @typescript-eslint/no-explicit-any */
if (typeof navigator === 'undefined') {
  (global as any).navigator = {
    locale: '',
    languages: [''],
    userAgent: [''],
  };
}

export default null;
