import { init, IntlVariations } from 'fbt';

init({
  translations: {},
  hooks: {
    getViewerContext: () => ({
      GENDER: IntlVariations.GENDER_UNKNOWN,
      locale: 'en_US',
    }),
  },
});
